import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import CenterBox from "./Center/Index";
const Box = styled.div`
  width: 100%;
  height: calc(100vh - 60px);
  min-height: 772px;
  margin: 60px 0 0 0;
  // .left {
  //   min-width: 200px;
  //   min-height: 750px;
  //   margin-right:60px;
  //   background: #ffffff;
  // }
  // .right {
  //   min-width: 200px;
  //   min-height: 750px;
  //   margin-left:60px;
  //   background: #ffffff;
  // }
  .mian {
    min-width: 750px;
    max-width: 1000px;
    min-height: 750px;s
    overflow: hidden;
    // background: #fdfdfd;
    // background: #fdfdfde6;
    background: rgba(253, 253, 253, 0.9);
    border-radius: 16px;
    z-index:10;
  }
  .float-boxs{
    z-index:9;
    .songth1ef{
      position:fixed;
      top: 5vh;
      left: 0vw;   
    }
  }
  .back{
    position:absolute;
    bottom:200px;
    left:38px;
  }
  .font-ascii {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono,
      Courier New, monospace;
    white-space: pre-wrap;
    line-height: 19.423px;
    opacity:0.85;
    user-select: none;
    position:fixed;
  }
`;
const Center = (props) => {
  const mainRef = useRef(null);
  const boxRef = useRef(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      // entries 是一个包含所有被观察元素的数组
      for (let entry of entries) {
        // entry.contentRect 包含了被观察元素的尺寸信息
        // console.log(
        //   "新的尺寸:",
        //   entry.contentRect.width,
        //   entry.contentRect.height
        // );
        // if (entry.contentRect.height > 863) {
        //   // console.log(boxRef.current.clientHeight);
        //   boxRef.current.style.height = `${entry.contentRect.height}px`;
        //   boxRef.current.style.margin = `120px 0 60px 0`;
        // }
        if (boxRef.current) {
          boxRef.current.style.height = `${entry.contentRect.height}px`;
          boxRef.current.style.margin = `120px 0 60px 0`;
        }
      }
    });

    // 将 ResizeObserver 关联到目标元素
    resizeObserver.observe(mainRef.current);

    // 组件卸载时清理 ResizeObserver
    return () => {
      resizeObserver.disconnect();
    };
  }, []); // 空数组表示只在组件挂载和卸载时运行
  return (
    <Box className="flexC" ref={boxRef}>
      <div className="left"></div>
      <div className="mian" ref={mainRef}>
        <CenterBox props={props.props}></CenterBox>
      </div>
      <div className="right"></div>
      <div className="float-boxs">
        <div className="songth1ef"></div>
      </div>
      <div className="back">
        <pre className="font-ascii">
          &nbsp;&nbsp;██████&nbsp;&nbsp;▒█████&nbsp;&nbsp;&nbsp;███▄&nbsp;&nbsp;&nbsp;&nbsp;█&nbsp;&nbsp;&nbsp;▄████&nbsp;▄▄▄█████▓&nbsp;██░&nbsp;██&nbsp;&nbsp;██▓▓█████&nbsp;&nbsp;&nbsp;█████▒{" "}
          <br />
          ▒██&nbsp;&nbsp;&nbsp;&nbsp;▒&nbsp;▒██▒&nbsp;&nbsp;██▒&nbsp;██&nbsp;▀█&nbsp;&nbsp;&nbsp;█&nbsp;&nbsp;██▒&nbsp;▀█▒▓&nbsp;&nbsp;██▒&nbsp;▓▒▓██░&nbsp;██▒▓██▒▓█&nbsp;&nbsp;&nbsp;▀&nbsp;▓██&nbsp;&nbsp;&nbsp;▒
          <br />
          ░&nbsp;▓██▄&nbsp;&nbsp;&nbsp;▒██░&nbsp;&nbsp;██▒▓██&nbsp;&nbsp;▀█&nbsp;██▒▒██░▄▄▄░▒&nbsp;▓██░&nbsp;▒░▒██▀▀██░▒██▒▒███&nbsp;&nbsp;&nbsp;▒████&nbsp;░{" "}
          <br />
          &nbsp;&nbsp;▒&nbsp;&nbsp;&nbsp;██▒▒██&nbsp;&nbsp;&nbsp;██░▓██▒&nbsp;&nbsp;▐▌██▒░▓█&nbsp;&nbsp;██▓░&nbsp;▓██▓&nbsp;░&nbsp;░▓█&nbsp;░██&nbsp;░██░▒▓█&nbsp;&nbsp;▄&nbsp;░▓█▒&nbsp;░{" "}
          <br />
          ▒██████▒▒░&nbsp;████▓▒░▒██░&nbsp;&nbsp;&nbsp;▓██░░▒▓███▀▒&nbsp;&nbsp;▒██▒&nbsp;░&nbsp;░▓█▒░██▓░██░░▒████▒░▒█░{" "}
          <br />
          ▒&nbsp;▒▓▒&nbsp;▒&nbsp;░░&nbsp;▒░▒░▒░&nbsp;░&nbsp;▒░&nbsp;&nbsp;&nbsp;▒&nbsp;▒&nbsp;&nbsp;░▒&nbsp;&nbsp;&nbsp;▒&nbsp;&nbsp;&nbsp;▒&nbsp;░░&nbsp;&nbsp;&nbsp;&nbsp;▒&nbsp;░░▒░▒░▓&nbsp;&nbsp;░░
          ▒░&nbsp;░&nbsp;▒&nbsp;░ <br />
          ░&nbsp;░▒&nbsp;&nbsp;░&nbsp;░&nbsp;&nbsp;░&nbsp;▒&nbsp;▒░&nbsp;░&nbsp;░░&nbsp;&nbsp;&nbsp;░&nbsp;▒░&nbsp;&nbsp;░&nbsp;&nbsp;&nbsp;░&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;░&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;▒&nbsp;░▒░&nbsp;░&nbsp;▒&nbsp;░&nbsp;░&nbsp;░&nbsp;&nbsp;░&nbsp;░{" "}
          <br />
          ░&nbsp;&nbsp;░&nbsp;&nbsp;░&nbsp;&nbsp;░&nbsp;░&nbsp;░&nbsp;▒&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;░&nbsp;&nbsp;&nbsp;░&nbsp;░&nbsp;░&nbsp;░&nbsp;&nbsp;&nbsp;░&nbsp;&nbsp;&nbsp;░&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;░&nbsp;&nbsp;░░&nbsp;░&nbsp;▒&nbsp;░&nbsp;&nbsp;&nbsp;░&nbsp;&nbsp;&nbsp;&nbsp;░&nbsp;░{" "}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;░&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;░&nbsp;░&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;░&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;░&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;░&nbsp;&nbsp;░&nbsp;&nbsp;░&nbsp;░&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;░&nbsp;&nbsp;░{" "}
          <br />
        </pre>
      </div>
    </Box>
  );
};

export default Center;
