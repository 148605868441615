import React from 'react';
import styled from 'styled-components';
import imgpalace from '../../images/loading.png'
const Bpp = styled.div` 
width:100vw;
height:100vh;
background:#ffffff;
.box{ 
    width:30px;
    height:30px;
    transform: translate(-50%,-50%);
    position:fixed;
    top:50%;
    left:50%; 
    img {
        width:30px;
        height:30px;
        animation: rload 0.4s steps(8, end)  infinite;
        @keyframes rload {
            0% {
                transform: rotate(1deg);
            }  
              100% {
                transform: rotate(359deg);
            }
        }
      }  
}
`
const Load = () => {
    const codeUrl = imgpalace
    return (
        <Bpp>
            <div className='box'>
                <img className='codeImage' src={codeUrl} alt="" />
            </div>
        </Bpp>
    );
}

export default Load;