import React from "react";
import styled from "styled-components"; //npm install --save styled-components
// import pdf from "../../download/yqf.pdf";
const Box = styled.div`
  .font-noshow {
    color: #fdfdfd;
  }
  .font-normal {
    font-size: 14px;
  }
  .outA {
    font-size: 21px;
  }
  .emojiLink {
    font-size: 21px;
    margin-right: 16px;
    padding: 5px;
  }
`;
const Songth1ef = () => {
  return (
    <Box>
      <p>✋你好，我叫杨青峰，在深圳从事前端开发🧑‍💻</p>
      <br />
      <p>📧邮箱：soullucklyman@163.com</p>
      <br />
      <div className="flexL">
        <span className="emojiLink">
          📖
          <a
            className="outA"
            href="https://blog.csdn.net/songth1ef"
            target="_blank"
            rel="noreferrer"
          >
            我的csdn
          </a>
        </span>
        <span className="emojiLink flexL">
          📝
          <a
            className="outA"
            href="http://codenote.songth1ef.com/"
            target="_blank"
            rel="noreferrer"
          >
            学习笔记
          </a>
        </span>
        <span className="emojiLink flexL">
          📝
          <a
            className="outA"
            href="http://evu2.songth1ef.com/"
            target="_blank"
            rel="noreferrer"
          >
            vue2组件库
          </a>
        </span>
      </div>
      <br />
      <p className="font-noshow flexC">
        ¸„.-•~¹°”ˆ˜¨  谢谢你看我的网站  ¨˜ˆ”°¹~•-.„¸
      </p>
      <p className="font-noshow flexC">𝒯𝒽𝒶𝓃𝓀 𝓎𝑜𝓊 𝒻𝑜𝓇 𝓁𝑜𝑜𝓀𝒾𝓃𝑔</p>
      {/* <a className="outA " href="">
        <span className="font-normal">微博</span>
      </a>
      <br /> */}

      {/* <p>ctrl a</p>
      <br /> */}
      {/* <p>计划学习后端，更多功能正在完善中,好像并没有多少时间学习:)</p> */}
      {/* <a href={pdf} download="yqf.pdf">简历下载</a> */}
      {/* <br />
      <a href="https://gitee.com/songth1ef/review" target="_blank">
        总结
      </a>
      <br />
      <br />
      <a href="https://leetcode.cn/" target="_blank">
        刷题
      </a>
      <br />
      <br />
      <a href="https://bestofjs.org/" target="_blank">
        技术
      </a>
      <br />
      <br />
      <a href="https://blog.csdn.net/songth1ef" target="_blank">
        我的csdn
      </a> */}
    </Box>
  );
};

export default Songth1ef;
