import React from "react";
import styled from "styled-components";

import TopPage from "./Layout/TopNav";
import BottomPage from "./Layout/Bottom";
import CenterPage from "./Layout/Center";

const Box = styled.div`
  .box {
    width: 100%;
    height: 100%;
    justify-content: space-between;
    .top {
      width: 100%;
    }
    .center {
      width: 100%;
      background: #fafafa;
    }
    .bottom {
      width: 100%;
      background: #ffffff;
      z-index: 20;
    }
  }
`;

const Topnav = (props) => {
  return <TopPage props={props.props}></TopPage>;
};
const Main = (props) => {
  return <CenterPage props={props.props}></CenterPage>;
};
const Bottom = (props) => {
  return <BottomPage props={props.props}></BottomPage>;
};

const Newindex = (props) => {
  return (
    <Box>
      <div className="box flexUP flexC">
        <div className="top">
          <Topnav props={props}></Topnav>
        </div>
        <div className="center">
          <Main props={props}></Main>
        </div>
        <div className="bottom">
          <Bottom props={props}></Bottom>
        </div>
      </div>
    </Box>
  );
};

export default Newindex;
