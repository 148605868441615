import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import {
  BrowserRouter as Router
} from 'react-router-dom'  //npm i react-router-dom@5.3.3   
// import {
//   HashRouter as Router
// } from 'react-router-dom'  //npm i react-router-dom@5.3.3   
import { renderRoutes } from 'react-router-config'  //npm i react-router-config -S
import routes from './router' //导入路由文件

import './css/style.css'  //导入公共样式

import { Provider } from 'react-redux';    //  导入仓库的组件 npm install --save react-redux

import store from './store' //导入仓库 

import Load from './pages/Load/Load'

class App extends React.Component {
  render() {
    return (
      <>
        <Suspense fallback={<Load> </Load>}>
          <Provider store={store}>
            {renderRoutes(routes)}
          </Provider>
        </Suspense>
      </>
    )
  }
}

ReactDOM.render(
  <Router>
    <App />
  </Router>
  ,

  document.getElementById('root')
);
