import { createStore, combineReducers } from 'redux';   //npm install --save redux

function CIPReducer(state = { num: 2022075244 }, action) {
    switch (action.type) {
        case 'ADD':
            return { value: state.value + 1 }
        default:
            return state
    }
}

function unnamedReducer(state = { name: 'songth1ef' }, action) {
    switch (action.type) {
        case 'ChangeName':
            return { name: 'SongTh1ef' }
        default:
            return state
    }
}

const reducers = combineReducers({ CIP: CIPReducer, unnamed: unnamedReducer })

var store = createStore(reducers)

export default store