import React, { useState } from "react";
import styled from "styled-components";
const Box = styled.div`
  .topNav {
    width: 100%;
    height: 60px;
    background: #252525;
    position: fixed;
    color: #fff;
    mix-blend-mode: multiply;
    z-index: 20;
  }
  .Logo {
    font-style: italic;
    font-size: 36px;
    color: #fff;
    under-line: none;
    text-decoration: none;
    position: fixed;
  }
  .navbar {
    min-width: 80px;
    min-height: 60px;
    padding: 0 10px;
    font-size: 22px;
    color: #fff;
    .father-tab {
      position: absolute;
      top: 60px;
      background: #0f0f0f;
      color: #fff;
      display: none;
    }
    .son-tab {
      min-width: 100px;
      height: 30px;
      padding: 4px 6px;
    }
  }
  .navbar:hover {
    background: #0f0f0f;
    .father-tab {
      display: flex;
    }
  }
  .right-menu-btn {
    position: fixed;
    right: 20px;
    font-size: 32px;
  }
  @media screen and (max-width: 999px) {
    .topNav {
      height: 90px;
    }
    .left,
    .right {
      display: none;
    }
    .right-menu-btn {
      display: block;
    }
  }
  @media screen and (min-width: 1000px) {
    .left,
    .right {
      display: flex;
    }
    .right-menu-btn {
      display: none;
    }
    .top-window{
      display: none;
    }
  }
  .top-window {
    width: 100%;
    max-height: 88vh;
    position: fixed;
    top: 90px;
    background: #252525;
    .window-bar {
      width: 100%;
      height: 90px;
      border-top: 1px solid #fff;
      font-size: 36px;
      .father-tab {
        padding: 0 10px;
      }
      .son-tab {
        padding: 0 10px;
        display: none;
      }
    }
    .window-bar:hover .son-tab {
      display: block;
    }
  }
`;
const TopNav = (props) => {
  const [leftNavList] = useState([
    // {
    //   name: "简介",
    //   path: "/info/songth1ef/songth1ef",
    // },
    {
      name: "作品",
      path: "/",
      children: [
        { name: "HTML", path: "/info/works/html", children: "" },
        // { name: "小程序", path: "/info/uni/songsneak", children: "" },
      ],
    },
    {
      name: "小程序",
      path: "/",
      children: [
        { name: "SongSneak概念版", path: "/info/uni/songsneak", children: "" },
        { name: "微信公众号", path: "/info/uni/wechat", children: "" },
      ],
    },
    {
      name: "CS",
      path: "/",
      children: [
        { name: "CSGO", path: "/info/csgo/cfg", children: "" },
        { name: "AIM", path: "/info/csgo/aim", children: "" },
      ],
    },
  ]);
  const [rightNavList] = useState([
    {
      name: "",
      path: "/",
      children: [{ name: "返回", path: "/", children: "" }],
    },
    {
      name: "",
      path: "/",
      children: [{ name: "返回", path: "/", children: "" }],
    },
    {
      name: "",
      path: "/",
      children: [{ name: "返回", path: "/", children: "" }],
    },
    // {
    //   name: "44falling",
    //   path: "/",
    //   children: [{ name: "返回", path: "/", children: "" }],
    // },
    // {
    //   name: "W.M.S",
    //   path: "/",
    //   children: [{ name: "返回", path: "/", children: "" }],
    // },
    // {
    //   name: "uDesign",
    //   path: "/",
    //   children: [{ name: "返回", path: "/", children: "" }],
    // },
  ]);
  const [rightBar, SetRightBar] = useState(false);
  const [allNavList] = useState([...leftNavList, ...rightNavList]);
  function jumpTo(params) {
    console.log(params.path);
    if (params.path !== "/") {
      props.props.history.push(params.path);
    }
  }
  function changeRightBar() {
    SetRightBar(!rightBar);
  }
  return (
    <Box className=" ">
      <div className="topNav flexA">
        <a href="/" className="Logo handon">
          SongTh1ef
        </a>
        {rightBar ? (
          <span className="right-menu-btn handon" onClick={changeRightBar}>
            X
          </span>
        ) : (
          <span className="right-menu-btn handon" onClick={changeRightBar}>
            ☰
          </span>
        )}
        {rightBar ? (
          <div className="top-window">
            {allNavList.map((item, index) => {
              return (
                <div key={index}>
                  {item.name ? (
                    <div className="window-bar flexC">
                      {item.children ? (
                        <div className="flexA">
                          <div className="father-tab">{item.name}</div>
                          {item.children.map((items, indexs) => {
                            return (
                              <span
                                key={indexs}
                                className="son-tab handon"
                                onClick={() => jumpTo(items)}
                              >
                                {items.name}
                              </span>
                            );
                          })}
                        </div>
                      ) : (
                        <span className="handon" onClick={() => jumpTo(item)}>
                          {item.name}
                        </span>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}
        <div className="left flexA">
          {leftNavList.map((item, index) => {
            return (
              <div key={index} className="navbar flexC">
                <span className="handon" onClick={() => jumpTo(item)}>
                  {item.name}
                </span>
                <span className="father-tab flexUP">
                  {item.children?.map((items, indexs) => {
                    return (
                      <div className="son-tab flexC" key={indexs}>
                        <span className="handon" onClick={() => jumpTo(items)}>
                          {items.name}
                        </span>
                      </div>
                    );
                  })}
                </span>
              </div>
            );
          })}
        </div>
        <div className="right flexA">
          {rightNavList.map((item, index) => {
            return (
              <div key={index} className="navbar flexC">
                <span className="handon" onClick={() => jumpTo(item)}>
                  {item.name}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </Box>
  );
};

export default TopNav;
