import React from "react";

import Index from '../pages/Index'

const routes = [
    {
        path: '/info',
        // component: React.lazy(() => import('../pages/Info/Info')),
        component: Index,
        routes: [
            {
                path: '/info/works/html',
                component: React.lazy(() => import('../pages/Works/Html'))
            },
            {
                path: '/info/csgo/aim',
                component: React.lazy(() => import('../pages/CSGO/Aim'))
            },
            {
                path: '/info/csgo/cfg',
                component: React.lazy(() => import('../pages/CSGO/Cfg'))
            },
            {
                path: '/info/uni/songsneak',
                component: React.lazy(() => import('../pages/Uni/SongSneak'))
            },
            {
                path: '/info/uni/wechat',
                component: React.lazy(() => import('../pages/Uni/Wechat'))
            },
            {
                path: '/info/songth1ef/songth1ef',
                component: React.lazy(() => import('../pages/songth1ef/songth1ef'))
            },
            {
                path: '/info/44/falling',
                component: React.lazy(() => import('../pages/44/Falling'))
            },
        ]
    },
    {
        path: '/html',
        component: React.lazy(() => import('../pages/Works/htmlPage/Index'))
    },
    {
        path: '/load',
        component: React.lazy(() => import('../pages/Load/Load'))
    },
    // {
    //     path: '/login',
    //     component: React.lazy(() => import('../pages/Login/Login'))
    // },
    // {
    //     path: '/register',
    //     component: React.lazy(() => import('../pages/Login/Register'))
    // },
    // {
    //     path:'/oldindex',
    //     component: React.lazy(() => import('../pages/oldIndex'))
    // },
    {
        path: '/',
        component: Index
    },
]

export default routes