/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import styled from "styled-components";
import ImagePlace from "./../../images/wechat.jpg"; //导入微信二维码为变量
import fbl from "./../../images/fbl.png"; //导入备案图片
const Box = styled.div`
  width: 100%;
  height: 60px;
  font-size: 14px;
  .fbl{
    img{ 
      width: 14px;
      height: 14px;
      vertical-align: middle;
      position: relative;
      top: -2px;
    }
  }
    }
  }
  .font-bottom{
    span{
      margin:0 5px;
    }
  }  
  .wxQR {
    position:relative;
    .codeImage {
      display: none;
    }
    a:hover .codeImage {
      width: 210px;
      height: 210px;
      display: block;
      position: absolute;
      top: -231px;
      left: -100px;
      z-index:20;
    }
  }
`;
const Bottom = () => {
  const FBL = fbl;
  const codeUrl = ImagePlace; //微信二维码地址
  return (
    <Box className="flexC">
      <span className="font-bottom flexC">
        <span className="">邮箱：soullucklyman@163.com</span>
        <span className="">
          <a
            rel="noreferrer"
            className="outA"
            href="http://beian.miit.gov.cn/"
            target="_blank"
          >
            粤ICP备2022075244号
          </a>
        </span>
        <span className="fbl">
          <a
            rel="noreferrer"
            className="outA"
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030602006933"
          >
            <img src={FBL} />
            粤公网安备 44030602006933号
          </a>
        </span>
        <span className="wxQR">
          <a className="outA handon">
            微信公众号
            <img className="codeImage" src={codeUrl} alt="" />
          </a>
        </span>
      </span>
    </Box>
  );
};

export default Bottom;
