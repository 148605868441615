/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router";
import { renderRoutes } from "react-router-config"; //npm i react-router-config -S
import IndexPage from "./../../songth1ef/songth1ef";

const Box = styled.div`
  .home {
    width: 750px;
    height: 750px;
    padding: 16px;
    // background: #fdfdfd;
    // display: flex;
    // flex-direction: row; /* 主轴方向为水平方向 */
    // flex-wrap: wrap; /* 如果子元素超过一行，自动换行 */
    // flex-flow: row wrap; /* 简写形式，指定主轴和换行 */
    // justify-content: flex-start; /* 沿主轴起始位置对齐 */
    // align-items: flex-start; /* 沿交叉轴起始位置对齐 */
    // border: 1px solid #fff;
    // border-radius: 16px;
    .nav {
      width: 250px;
      height: 250px;
      background: #fdfdfd;
      border: 1px solid #fff;
      box-sizing: border-box;
      padding: 20px;
      .font-nav {
        font-size: 18px;
      }
    }
    .nav:hover {
      background: #f9f9f9;
      box-shadow: -2px 0px 4px #f9f9f9, /*左边阴影*/ 0px -2px 4px #f9f9f9,
        /*上边阴影*/ 2px 0px 4px #f9f9f9, /*右边阴影*/ 0px 2px 4px #f9f9f9; /*下边阴影*/
    }
  }
  .other-page {
    padding: 16px;
  }
`;
const Index = (props) => {
  const location = useLocation();
  const [isHome, SetisHome] = useState(true);
  const [isOutNav, SetisOutNav] = useState(true);
  const [navList, Setnavlist] = useState([
    {
      name: "首页",
      path: "/",
      children: [
        { name: "返回", path: "/", children: "" },
        { name: "简介", path: "/info/songth1ef/songth1ef", children: "" },
      ],
    },
    {
      name: "作品",
      path: "/",
      children: [
        { name: "返回", path: "/", children: "" },
        { name: "HTML", path: "/info/works/html", children: "" },
        { name: "小程序", path: "/info/uni/songsneak", children: "" },
      ],
    },
    {
      name: "CS",
      path: "/",
      children: [
        { name: "返回", path: "/", children: "" },
        { name: "CS", path: "/info/csgo/cfg", children: "" },
        { name: "AIM", path: "/info/csgo/aim", children: "" },
      ],
    },
    {
      name: "44falling",
      path: "/",
      children: [{ name: "返回", path: "/", children: "" }],
    },
    {
      name: "聊天",
      path: "/",
      children: [{ name: "返回", path: "/", children: "" }],
    },
    {
      name: "WATCHMESHINE",
      path: "/",
      children: [{ name: "返回", path: "/", children: "" }],
    },
    {
      name: "小程序",
      path: "/",
      children: [
        { name: "返回", path: "/", children: "" },
        { name: "SongSneak概念版", path: "/info/uni/songsneak", children: "" },
        { name: "微信公众号", path: "/info/uni/wechat", children: "" },
      ],
    },
    {
      name: "异世界",
      path: "/",
      children: [{ name: "返回", path: "/", children: "" }],
    },
    {
      name: "支持",
      path: "/",
      children: [{ name: "返回", path: "/", children: "" }],
    },
  ]);
  const [innerNavList, SetinnerNavList] = useState([]);
  useEffect(() => {
    if (location.pathname === "/") {
      SetisHome(true);
    } else {
      SetisHome(false);
    }
  }, []);
  function outNav(item) {
    SetisOutNav(false);
    SetinnerNavList(item.children);
  }
  function innerNav(item) {
    if (item.path === "/") {
      SetisOutNav(true);
    } else {
      props.props.history.push(item.path);
    }
  }
  return (
    <Box>
      {isHome ? (
        <div className="home">
          <IndexPage></IndexPage>
        </div>
      ) : (
        <div className="other-page">
          {renderRoutes(props.props.route.routes) || '404'}
        </div>
      )}
    </Box>
  );
};

export default Index;
